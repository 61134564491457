.Oops-div{
    margin-top:50px
}
.Oops{
    font-size: 30px;
    text-align: center;
}
.OopsTwo{
    font-size: 18px;
    text-align: center;
}
.image-css-404{
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top:70px
}