
.secondarytitle {
    font-size: 3ch;
    font-weight: 500;
}



.hwotaniroclub {
    padding-top: 10px;
    width: 100%;
    height: 90vh;
}

.Founder-text-css{
    font-size:20px;
    text-align:center;
    margin-top:20px;
    font-weight:700px;
}
.avater-rishab-jain{
    margin-top:20px;
    justify-content: center;
    align-items: center;
    display: flex;
    
    
}
.rishab-jain-textt{
    font-size: 25px;
    text-align: center;
    margin-top:15px;
    margin-bottom:0;
    padding-bottom:0;
}
.rishab-jain-textt-two{
    font-size: 55px;
    top:0;
    position: absolute;
    margin-top:120px;
    margin-left:50px

}
.rishab-jain-text{
    font-size: 17px;
    text-align: center;
    margin-top:7px;
    margin-bottom:0;
    padding-bottom:0;
}
.rishab-jain-text-tag{
    font-size: 14px;
    text-align: center;
    color:grey
}

.box-width-for-side-corner-thing-dshfsalhdfadsf{
    width:200px;
    
    right:0;
    position: absolute;
    margin-right:15%;
}
.education-css-text{
  font-size: 21px;
  margin-top:20px
}
.under-education-css-text{
    margin-left:10px
}

.rishab-jain-text-tagg{
    font-size: 20px;
    text-align: center;
    color:#7d9ad4 ;
    width: 550px;
    padding-top:20px;
    margin: auto
}

.rishab-jain-text-tagg-two{
    font-size: 20px;
    text-align: left;
    color:#7d9ad4 ;
    width: 50%;
    top:0;
    position: absolute;
    margin-top:200px;
    margin-left:50px

}

.more-people{
    margin-top:20px;
    justify-content: center;
    align-items: center;
    display: flex;
  
   
}
.Website-Creator-text-css{
    font-size:20px;
    text-align:center;
    margin-top:40px;
    font-weight:700px;
}

.avater-size-css{
    width:100px !important;
    height:100px !important;    
}
.op-team-two{
    display:none;
}

.at-least-one-css{
    text-decoration: underline;
    text-decoration-color: #000000;
    text-decoration-thickness: 1px;
    text-underline-offset: 4px;
}

@media all and (max-width: 1155px) {

    .box-width-for-side-corner-thing-dshfsalhdfadsf{
        width:160px;
        
        right:0;
        position: absolute;
        margin-right:15%;
    }

    .education-css-text{
        font-size: 18px;
        margin-top:20px
    }

    @media all and (max-width: 886px) {

        .box-width-for-side-corner-thing-dshfsalhdfadsf{
            width:140px;
            
            right:0;
            position: absolute;
            margin-right:15%;
        }

        .education-css-text{
            font-size: 15px;
            margin-top:20px
        }

    }    

    @media all and (max-width: 745px) {
        .avater-size-css{
            width:65px !important;
            height:65px !important;
        }
        .rishab-jain-text{
            font-size: 15px;
            text-align: center;
            margin-top:7px;
            margin-bottom:0;
            padding-bottom:0;
        }
        .rishab-jain-text-tag{
            font-size: 13px;
            text-align: center;
            color:grey
        }

        @media all and (max-width: 735px) {

            .rishab-jain-text-tagg-two{
                font-size: 20px;
                text-align: left;
                color:#7d9ad4 ;
                width: auto;
                top:0;
                position: absolute;
                margin-top:430px;
                margin-left:50px;
                padding-right:50px

            }

            .box-width-for-side-corner-thing-dshfsalhdfadsf{
                width:100px;
                
                right:0;
                position: absolute;
                margin-right:15%;
            }

            .rishab-jain-textt-two{
                font-size: 40px;
                top:0;
                position: absolute;
                margin-top:130px;
                margin-left:50px
            
            }

            .education-css-text{
                display:none;
            }
            .education-css-textt{
                display:block;
                font-size: 21px;
                margin-top:20px;
                margin-top:180px;
                margin-left:50px;
                position: absolute;
            }

        }



        @media all and (max-width: 580px) {
            .rishab-jain-text-tagg{
                font-size: 20px;
                text-align: center;
                color:#7d9ad4 ;
                width: auto;
                padding-top:20px
             
            }
        }

        @media all and (max-width: 510px) {
            .avater-size-css{
                width:60px !important;
                height:60px !important;
            }
            .rishab-jain-text{
                font-size: 14px;
                text-align: center;
                margin-top:7px;
                margin-bottom:0;
                padding-bottom:0;
            }
            .rishab-jain-text-tag{
                font-size: 12px;
                text-align: center;
                color:grey
            }

            .op-team-one{
                display:none;
            }
            .op-team-two{
                display:block;
            }
        }

        @media all and (max-width: 500px) {

            .rishab-jain-text-tagg-two{
                font-size: 20px;
                text-align: left;
                color:#7d9ad4 ;
                width: auto;
                top:0;
                position: absolute;
                margin-top:430px;
                margin-left:50px;
                padding-right:20px

            }

        }
        
        @media all and (max-width: 455px) {

           

            .rishab-jain-text-tagg-two{
                font-size: 20px;
                text-align: left;
                color:#7d9ad4 ;
                width: auto;
                top:0;
                position: absolute;
                /* margin-top:430px; */
                margin-left:1px;
                padding-right:10px

            }

            .rishab-jain-textt-two{
                display: none;
            
            }

            .education-css-textt{
                display:none;
            }

            .avater-size-css-two{
                margin-left: auto;
                /* margin-top:100px; */
                margin-right:160px;
                width: 150px !important;
                height: 200px !important;
            }

            .box-width-for-side-corner-thing-dshfsalhdfadsf{
                width:100px;
                
                right:0;
                position: absolute;
                margin-right:17%;
            }

        }

        @media all and (max-width: 422px) {

            
            .rishab-jain-textt-two{
                font-size: 30px;
                top:0;
                position: absolute;
                margin-top:130px;
                margin-left:50px
            
            }
/* 
            .education-css-textt{
                display:block;
                font-size: 17px;
                margin-top:20px;
                margin-top:180px;
                margin-left:50px;
                position: absolute;
            } */

        }

        @media all and (max-width: 385px) {

            
            .rishab-jain-textt-two{
                font-size: 25px;
                top:0;
                position: absolute;
                margin-top:130px;
                margin-left:50px
            
            }

            /* .education-css-textt{
                display:block;
                font-size: 15px;
                margin-top:20px;
                margin-top:180px;
                margin-left:50px;
                position: absolute;
            } */

        }

        @media all and (max-width: 360px) {

            .box-width-for-side-corner-thing-dshfsalhdfadsf{
                width:100px;
                
                right:0;
                position: absolute;
                margin-right:30%;
                margin-top:120px
            }

        }
    }
}
