.center-stuff{
    align-items: center;
    justify-content: center;
    display: flex;
    margin:auto;
    margin-top:50px
}
.Applicant-Portal{
    margin-left: 50px;
    font-size: 30px;
}
.Applicant-Name{
    font-size: 17px;
}

.Applicant-Round{
    font-size: 17px;
    margin-left:30px
}
.background-ib{
    background-color:rgb(235, 235, 235);
    height: 100vh;
    margin-top:60px
}

.Important-Details{
    font-size: 20px;
    margin-left:5px;
}

.Important-Detailss{
    font-size: 17px;
    margin-left:5px;
}
.ID-Box{
    background-color:white;
    width:500px;
    height:300px;
    margin:auto;
    padding-left:30px;
    padding-top:30px
}