/* #7d9ad4 */

body {
    font-family: 'Hedvig Letters Serif', serif;
    overflow-x: hidden;
    background-color:white;
    font-variant-ligatures: none;
    width:100%;
    /* background-color:orange */
    /* background-color:black */

    /* background-image: url(/src/logos/blob.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
}

.iro-award-ceremony-finalist-1 {
    display: none;
}

.iro-jimmytalk-finalists-1 {
    display: none;
}

.iro-teamchallnage-finalsts-1 {
    display: none;
}


.finalsfooterphoto-2 {
    display: none;
}

.aboutfinalsiamget {
    display: none;
}

.finalspressrelase {
    margin-left: 10%;
    margin-right: 10%;
}

.finalspressrelases {

    margin-right: 10%;
}
  

.second-video{
    display: none;
}



  /* Media query for smaller screens */
  
  




.containere {
    position: relative;
    display: flex;
    align-items: center;
    gap: 14px; /* Adjust based on gap-x-6 and sm:gap-x-3.5 */
    overflow: hidden;
    background-image: url(banner.svg);
    /* background-image: linear-gradient(
        90deg,
        hsl(219deg 35% 90%) 0%,
        hsl(219deg 35% 90%) 4%,
        hsl(219deg 35% 90%) 9%,
        hsl(219deg 35% 90%) 14%,
        hsl(219deg 35% 92%) 21%,
        hsl(219deg 35% 90%) 49%,
        hsl(219deg 35% 92%) 78%,
        hsl(219deg 35% 90%) 85%,
        hsl(219deg 35% 96%) 91%,
        hsl(219deg 35% 90%) 96%,
        hsl(219deg 35% 90%) 100%
      ); */
    background-size: cover;
    padding: 4px 14px; /* Adjust based on px-6 py-2.5 and sm:px-3.5 */
    font-size: 0.875rem; /* text-sm */
    line-height: 1.25rem; /* leading-6 */
  }


.background-shapee {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) blur(32px);
    width: 36.0625rem;
    height: auto;
    background-image: linear-gradient(to right, #ff80b5, #9089fc);
    opacity: 0.3;
    z-index: -10;
}
.background-shapee:before {
    content: "";
    display: block;
    padding-top: 53.48837209302326%; /* 310/577 * 100 to maintain aspect ratio */
}
.shape-lefte {
    left: max(-7rem, calc(50% - 52rem));
    clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%);
}
.shape-righte {
left: max(45rem, calc(50% + 8rem));
clip-path: polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%);
}
.infoe {
    display: flex;
    color: #111827; /* text-gray-900 */

    margin: auto
}
.infoe strong {
    font-weight: 600; /* font-semibold */
}
.infoe svg {
    margin: 0 8px; /* Adjust based on mx-2 */
    vertical-align: middle;
}

.termsacondsitonfs {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
}

.infoe {
    display: flex;
    color: #111827; /* text-gray-900 */

    margin: auto
}
.infoe strong {
    font-weight: 600; /* font-semibold */
}
.infoe svg {
    margin: 0 8px; /* Adjust based on mx-2 */
    vertical-align: middle;
}

.centrlogos {
    display: flex;
    justify-content: center;
    align-items: center;
}

.smallscreenmenu {
    display: none;
}

.tswhenship {
    display: block;
}

.twdasperandc {
    display: none;
}

.pandspad {
    padding-left: 60px;
}

.pandspadt {
    padding-left: 80px;
}

.cmomptitle {
    text-align: center;
    font-size: 4.7ch;
    font-weight: 500;
    padding-bottom: 50px;
}

.sponsersssfe {
    --shadow-color: 0deg 0% 63%;
    --shadow-elevation-low:
      0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
      0.4px 0.8px 1px -1.2px hsl(var(--shadow-color) / 0.34),
      1px 2px 2.5px -2.5px hsl(var(--shadow-color) / 0.34);
    --shadow-elevation-medium:
      0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
      0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
      2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
      5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);
    --shadow-elevation-high:
      0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.34),
      1.5px 2.9px 3.7px -0.4px hsl(var(--shadow-color) / 0.34),
      2.7px 5.4px 6.8px -0.7px hsl(var(--shadow-color) / 0.34),
      4.5px 8.9px 11.2px -1.1px hsl(var(--shadow-color) / 0.34),
      7.1px 14.3px 18px -1.4px hsl(var(--shadow-color) / 0.34),
      11.2px 22.3px 28.1px -1.8px hsl(var(--shadow-color) / 0.34),
      17px 33.9px 42.7px -2.1px hsl(var(--shadow-color) / 0.34),
      25px 50px 62.9px -2.5px hsl(var(--shadow-color) / 0.34);

}

.wwhyiro {
    text-align: center;
    font-size: 6ch;
}

.blibblob {
    position: absolute;
    opacity: 0.45;
    margin-left: 150px;
    width: 300px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    animation: animate 10s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite
      alternate forwards;
    filter: blur(40px);
    z-index: -1;
    background: linear-gradient(
      47deg,
      hsl(221deg 39% 77%) 0%,
      hsl(221deg 41% 76%) 11%,
      hsl(221deg 43% 74%) 21%,
      hsl(221deg 44% 73%) 32%,
      hsl(221deg 45% 72%) 42%,
      hsl(221deg 46% 71%) 53%,
      hsl(221deg 47% 70%) 64%,
      hsl(220deg 48% 69%) 76%,
      hsl(220deg 49% 68%) 88%,
      hsl(220deg 50% 66%) 100%
    );
  
    /*  Optional  */
    /*   box-shadow: inset 50px 50px 17px 0px #ff588b,
      inset 100px 100px 17px 0px #66319f; */
  }
  
  /* @keyframes animate {
    0% {
      translate: 40% 10%;
      border-radius: 100% 20% 30% 70% / 100% 85% 92% 74%;
    }
    10% {
        translate: 40% 40%;
        border-radius: 100% 40% 30% 70% / 100% 85% 92% 74%;
    }
    50% {
      translate: 42% 20%;
      border-radius: 100% 71% 47% 70% / 81% 15% 22% 54%;
      rotate: 41deg;
      scale: 1.15;
    }
    100% {
      translate: 40% 10%;
      border-radius: 100% 75% 92% 74% / 60% 80% 30% 70%;
      rotate: -60deg;
      scale: 1.05;
    }
  } */

  @keyframes animate {
    0% {
      translate: 30% 25%;
      border-radius: 60% 40% 30% 70% / 100% 85% 92% 74%;
    }
    30% {
        translate: 0% 11%;
        border-radius: 20% 71% 47% 70% / 81% 15% 22% 54%;
        rotate: 71deg;
        scale: 1.05;
    }
    50% {
      translate: 0% 11%;
      border-radius: 20% 71% 47% 70% / 81% 15% 22% 54%;
      rotate: 41deg;
      scale: 1.05;
    }
    100% {
      translate: -35% 40%;
      border-radius: 100% 75% 92% 74% / 60% 80% 30% 70%;
      rotate: -60deg;
      scale: 1.05;
    }
  }
  

.container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.avater-size-css-two{
    margin-left: auto;
    margin-top:100px;
    margin-right:130px;
    width: 250px !important;
    height: 350px !important;
}

/* Light and dark */

.dark {
    -webkit-filter: invert(100%);
    filter: invert(100%);

    .avater-size-css{
        -webkit-filter: invert(100%);
        filter: invert(100%);
    }
    .avater-size-css-two{
        -webkit-filter: invert(100%);
        filter: invert(100%);
    }
    .rishab-jain-video-css{
        -webkit-filter: invert(100%);
        filter: invert(100%);
        box-shadow: 0 0 10px rgba(226, 243, 243, 0.5);
    }

    .partner-Platinum, .partner-Gold, .partner.Silver, .partner-Bronze {
        -webkit-filter: invert(100%);
        filter: invert(100%);

    }

    .image-css-invert-none{
        -webkit-filter: invert(100%);
    }

    .containere {
        -webkit-filter: invert(100%);
    }

    .canvaformediakit {
        -webkit-filter: invert(100%);
    }

    .Marquee-css-image{
        -webkit-filter: invert(100%);
    }
    .no-invert-image-partners-css{
        -webkit-filter: invert(100%);
        filter: invert(100%);
    }
    .hidden-button-css-id-5645fglksjdhrgdfdff{
        display: block;
    }
    .hidden-button-css-id-32y23grjhdgfhjsfadfdj{
        display:none;
    }
    .blibblob {
        display:none;
    }
    .coolhover-test{
        -webkit-filter: invert(100%);
    }
    .nottypurple{
        display:none;
    }
    .nottypurplee{
        display:block;
    }
    .wwhyiro {
        -webkit-filter: invert(100%);
    }
    .cool-image-guy-holding-blocks{
        -webkit-filter: invert(100%);
    }
    .footercool{
        display:none;
    }
    .footercooll{
        display:block;
    }
    .bigblueonthewing {
        -webkit-filter: invert(100%);
    }
    .bigblueonthewingg{
        color: black;
        -webkit-filter: invert(100%);
    }
    .hover-change-color-icon-footer:hover{
        -webkit-filter: invert(100%);
    }
    .news-paper-logo-css{
        -webkit-filter: invert(100%);
    }
    .smaller-avater-professor-css-no-invert{
        -webkit-filter: invert(100%);
    }
    .Highly-motivated-Unlimited-potentiall .Exceptionally-motivated-Limitless-potential { 
        -webkit-filter: invert(100%);
    }
    .register-text-gradient-move {
        -webkit-filter: invert(100%);
    }
}
  
.dark,
    .light {
    transition: 1s;
}

.pressreltitle {

    font-size: 2ch;

}

.light{
    -webkit-filter: invert(0);
    filter: invert(0);  

    .rishab-jain-video-css{
        -webkit-filter: invert(0);
        filter: invert(0);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }

    .image-css-invert-none{
        -webkit-filter: invert(0);
    }

    .Marquee-css-image{
        -webkit-filter: invert(0);
    }
    .no-invert-image-partners-css{
        -webkit-filter: invert(0);
        filter: invert(0);
    }
    .hidden-button-css-id-32y23grjhdgfhjsfadfdj{
        display:block;
    }
    .hidden-button-css-id-5645fglksjdhrgdfdff{
        display: none;
    }
    .nottypurple{
        display:block;
        -webkit-filter: invert(0);
    }
    .nottypurplee{
        display:none;
    }
    .footercool{
        display:block;
    }
    .footercooll{
        display:none;
    }
    .bigblueonthewing {
        -webkit-filter: invert(0);
    }
    .bigblueonthewingg{
        color: #7d9ad4;
        -webkit-filter: invert(0);
    }
}

.hidden-button-css-id-5645fglksjdhrgdfdff{
    display: none;
}




.princeton {
    position: relative;
    margin: 0 auto;
    max-width: 1300px;
    padding: 30px;
    border: thick double #000;
    border-radius: 10px;
    word-wrap: break-word; /* Add this line */
}

.bigblueonthewing {
    color: #7d9ad4;
}


.smallerlink {
    color: gray;
}

.smallerlinkfornavvy{
    color: gray;
    margin-top: 20px;

}


.princeton::before,
.princeton::after {
    content: "";
    position: absolute;
    top: 50%;
    height: 2px; /* Adjust the height of the lines as needed */
    border: thick double #000000;
    background-color: white;
}

.coolhover-test{
    height: 3px;
    width: 100%;
    background-image: linear-gradient(
        265deg,
        hsl(221deg 39% 77%) 0%,
        hsl(221deg 41% 76%) 11%,
        hsl(221deg 43% 74%) 21%,
        hsl(221deg 44% 73%) 32%,
        hsl(221deg 45% 72%) 42%,
        hsl(221deg 46% 71%) 53%,
        hsl(221deg 47% 70%) 64%,
        hsl(220deg 48% 69%) 76%,
        hsl(220deg 49% 68%) 88%,
        hsl(220deg 50% 66%) 100%
    );

}
.princeton::before {
    left: -50%; /* Extend to the left */
    width: 50%;
}

.princeton::after {
    right: -50%; /* Extend to the right */
    width: 50%;
}


.princetontitle {
    padding-left: 50px;
    padding-right: 50px;
}


.princeton-two {
    position: relative;
    margin: 0 auto;
    width:fit-content;
    padding: 30px;
    border: thick double #000;
    border-radius: 10px;
    word-wrap: break-word; /* Add this line */

    box-sizing: content-box; /* or box-sizing: border-box; depending on your layout */
    white-space: normal;
    overflow: visible;
}

.pressrelcontent {
    text-align: left;
    font-size: 16px;
}

.princeton-two::before,
.princeton-two::after {
    content: "";
    /* position: absolute;
    top: 50%; */
    height: 2px; /* Adjust the height of the lines as needed */
    /* border: thick double #000000; */
    background-color: white;
}


.cool-image-guy-holding-blocks-od{
    align-items: center;
    justify-content: center;
    display: flex;
}

.cool-image-guy-holding-blocks{
    border: thick double #7d9ad4;
    z-index: -1;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    align-items: center;
    justify-content: center;
    display: flex;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.princeton-two::before {
    left: -50%; /* Extend to the left */
    width: 50%;
}

.princeton-two::after {
    right: -50%; /* Extend to the right */
    width: 50%;
}

.emailbox-css{
    margin-top: 50px;
    border: 1px solid grey;
    padding:200px;
}

.princeton-three {
    width:100%;
    overflow-wrap:break-word
}

.Icon-Transition {
    transition: background-color 3s ease-in-out;
}

.APPLY-BY-text{
    font-size: 17px;
    color:grey;
    margin:0;
    padding:0;
}


.Light-Icon-Transition{
    color:rgb(57, 57, 57);
    transition: .2s;
}
.Light-Icon-Transition:hover{
    color:rgb(0, 0, 0);
    transition: .2s;
}

.Dark-Icon-Transition{
    color:rgb(57, 57, 57);
    transition: .2s;
}
.Dark-Icon-Transition:hover{
    color:rgb(0, 0, 0);
    transition: .2s;
}

.foottitle {
    margin: 12px;
    font-size: 16px;
}


.br{
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.no-invert-image-partners-css{
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.rowpad1 {
    padding-top: 100px;
}

.date {
    color: #626262;
}

.rowpad2 {
    padding-top: 200px;
}

.rowpad22 {
    padding-top: 80px;
}


.discord-link{
    color:rgb(39, 39, 39);
    margin-top:8px;
    padding-left:20px;
}

.discord-link-two{
    color:black;
}

.discord-linkk{
    color:rgb(255, 255, 255);
    padding-left:3px;
}


/* .maintitle {
    font-size: 10ch;
    font-weight: 500;
} */

.maintitlee {
    font-size: 5ch;
    font-weight: 500;
    text-align: center;
}

.maintitleee {
    font-size: 5ch;
    font-weight: 500;
}


.importitle {
    font-size: 3ch;
}


.comptitle {
    font-size: 4.7ch;
    font-weight: 500;
    padding-bottom: 50px;
}
.comptitleee {
    font-size: 5ch;
    font-weight: 500;
    padding-bottom: 50px;
}

.wwhyirolowertitle {
  
    font-size: 2ch;
    width:800px;
    
}

.lowertitle {
    font-size: 2ch;
    
}

.iei-title {
    font-size: 3ch;
    

}

.iei-summary {
    font-size: 2ch;
    color: gray;
    

}


.lowertitlee {
    font-size: 2ch;
    text-align: center;
}

.lowertitleee {
    font-size: 2ch;
}


.lowertitle2 {
    font-size: 2.2ch;
    color: black;
}


.lowertitle22 {
    padding-bottom: 5px;
    font-size: 2.7ch;
    
}


/* .whyiro_pad {
    padding-left: 200px;
} */

.lowertitle-ls {
    font-size: 2.5ch;
    line-height: 2.0;
    
}

.mogsep {
    padding-top: 30px;
}
.sepvert {
    background-color: black;
    height:110px;
    width:2px;
    padding: 0; /* Remove default padding */
    margin-left:30px
}

.complowertitle {
    font-size: 2.7ch;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}


.mainimg {
    display : block;
    margin-top:45px;
  
    width:410px;
    height:auto;

    margin-left:auto;
    margin-right:90px;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    z-index: 999;
}
.mainimg-two {
    display : block;
    margin : auto;
    width:350px;
    height:auto;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}


.mainimgg {

  
  
    width:300px;
    height:auto;



    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.Response-submitted{
    font-family: 'Hedvig Letters Serif', serif;
    font-size: 17px;
}


/* button css */

.btn-Homebutton{
    color:black;
    border:none;
    background-color:transparent;
    margin-left:20px;
    font-size: 16px;

}

.btn-aboutbutton{
    color:black;
    border:none;
    background-color:transparent;
    margin-left:15px;
    margin-right:15px;
    font-size: 16px;
}

.btn-newsbutton{
    color:black;
    border:none;
    background-color:transparent;
    margin-left:25px;
}


.social-media-icon-css{
    margin-left:50px;
}

.hover-change-color-icon-footer{
    color: black;
    transition: .2s;
}
.hover-change-color-icon-footer:hover{
    color: #7d9ad4;
    transition: .2s;
}

.dhfgaskjhdgasdhjfgasjdgfaskjdhfghjaskdfkasjdhgasdf{
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.btn-resourcesbutton{
    color:black;
    border:none;
    background-color:transparent;
    margin-left:20px;
    font-size: 17px;
}
.btn-morebutton{
    color:black;
    border:none;
    background-color:transparent;
    margin-right:20px;
    font-size: 17px;
}
.btn-resourcesbutton{
    color:black;
    border:none;
    background-color:transparent;
    margin-right:20px;
    font-size: 17px;
}
.btn-contactbutton{
    color:white;
    border:none;
    background-color:black;
    height:40px;
    border-radius: 40px;
    font-size: 17px;
}
.btn-registerbutton{
    color:white;
    border:none;
    background-color:black;
    height:40px;
    border-radius: 40px;
    font-size: 17px;
    margin-right: 9px;
    margin-left: 15px;
}
.btn-signInbutton {
    color:white;
    border:none;
    background-color:black;
    height:40px;
    border-radius: 40px;
    font-size: 17px;
    margin-right: 9px;
    margin-left: 15px;
}
.btn-signInbuttonColor{
    color:white;
}
.btn-DensityMediumIconbutton{
    color:black;
    border:none;
    background-color: transparent;
    display:none
}

.hidden-id-dsfgsdjhfgkjdshfgaskhjfgaksjdff{
    display:none
}

.btn-sunbutton{
    color:black;
    border:none;
    background-color:transparent;
}

.btn-submit-news-button{
    color:white;
    border:none;
    background-color:black;
    height:40px;
    border-radius: 40px;
    font-size: 17px;
    margin-top:20px;
}

.discord-link-svg {
    color: #393939;
}

.margin-top-Highly-motivated-Unlimited-potential{
    margin-top:80px;
}
.Highly-motivated-Unlimited-potential {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #000000; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
    font-size: 25px;

 } 


 .register-text-gradient-move {
    font-weight: 600;
    font-size:15px;
    background-image: linear-gradient(
        265deg,
        hsl(221deg 39% 77%) 0%,
        hsl(221deg 41% 76%) 11%,
        hsl(221deg 43% 74%) 21%,
        hsl(221deg 44% 73%) 32%,
        hsl(221deg 45% 72%) 42%,
        hsl(221deg 46% 71%) 53%,
        hsl(221deg 47% 70%) 64%,
        hsl(220deg 48% 69%) 76%,
        hsl(220deg 49% 68%) 88%,
        hsl(220deg 50% 66%) 100%
    );
    -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;  
    animation: rainbow-animation 130s linear infinite;
  }
  
  @keyframes rainbow-animation {
      to {
          background-position: 4500vh;
      }
  }

 
 .Highly-motivated-Unlimited-potential .Exceptionally-motivated-Limitless-potential { 
     background:white; 
     padding:0 10px; 
 }


.margin-top-Highly-motivated-Unlimited-potentiall{
    margin-top:80px;
}
.Highly-motivated-Unlimited-potentiall {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #000000; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
    font-size: 25px;

 } 
 
 .Highly-motivated-Unlimited-potentiall .Exceptionally-motivated-Limitless-potential { 
     background: #b1c8f1; 
     padding:0 10px; 
     border-radius: 5px;
 }

 /* .why-iro-div{
    width:660px;
    margin-left:25px;
 } */
 .img-iro-div-push-left{
    margin-top:10px;
 }

.footercool {
    background-image: linear-gradient(
    45deg,
    hsl(219deg 33% 85%) 0%,
    hsl(219deg 33% 86%) 10%,
    hsl(220deg 33% 88%) 22%,
    hsl(220deg 33% 90%) 34%,
    hsl(220deg 33% 91%) 46%,
    hsl(220deg 33% 93%) 58%,
    hsl(220deg 33% 95%) 69%,
    hsl(220deg 33% 97%) 81%,
    hsl(220deg 33% 98%) 91%,
    hsl(0deg 0% 100%) 100%
    );
    padding:70px;
}


.footercooll {
    background-image: white;
    border: 2px solid black; 
    padding:70px;
}


.why-iro-setup-two-hidden-other-one{
    display:none;
}

.why-iro-width-box{
    width:650px;
    word-wrap: break-word;
    white-space: normal;
    overflow: visible;
}

.why-iro-width-boxx{
    width:100%;

}


.nottypurple {
    margin-top: 70px;
  
    background-image: linear-gradient(
        90deg,
        hsl(0deg 0% 100%) 0%,
        hsl(219deg 35% 98%) 4%,
        hsl(219deg 35% 96%) 9%,
        hsl(219deg 35% 94%) 14%,
        hsl(219deg 35% 92%) 21%,
        hsl(219deg 35% 90%) 49%,
        hsl(219deg 35% 92%) 78%,
        hsl(219deg 35% 94%) 85%,
        hsl(219deg 35% 96%) 91%,
        hsl(219deg 35% 98%) 96%,
        hsl(0deg 0% 100%) 100%
      );
    padding-bottom: 70px;
}

.nottypurplee{
    margin-top: 70px;
  
    background-image: white;
    padding-bottom: 40px;
}

.get-notified {
    font-size:2.2ch;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 20px;
}

.registerbutton {

    color: white;
    cursor: pointer;
    transition: 2s;
    width: 205px;
  
    background-image: linear-gradient(
    265deg,
    hsl(221deg 39% 77%) 0%,
    hsl(221deg 41% 76%) 11%,
    hsl(221deg 43% 74%) 21%,
    hsl(221deg 44% 73%) 32%,
    hsl(221deg 45% 72%) 42%,
    hsl(221deg 46% 71%) 53%,
    hsl(221deg 47% 70%) 64%,
    hsl(220deg 48% 69%) 76%,
    hsl(220deg 49% 68%) 88%,
    #7d9ad4 100%
    );
    border-radius: 15px;

    margin-bottom:0px;
    padding-bottom:0px;
    

}



.registerbuttonn {
    color: white;
    cursor: pointer;
    width: 205px;
    background-color: black;
    border-radius: 15px;
    margin:0;
    padding:0;
}


.Competition-Format-resize-when-small-screen-hide-none{
    display:none;
}

.side-bar-popover-button-css{
    border: none;
    background-color:white;
    font-size: 17px;
}

.secondinsideytwo {

   
    border-radius:20px;
    border: none;
    color: black;
    background-color:white;
   

}

.side-bar-2-popover-button-csss{
    transition: 2s ease-in-out;
    margin-top:35px;
    border-radius:20px;
    border: none;
    background-color:white;
    font-size: 30px;
    margin-left:45px
}

.side-bar-smaller-2 {
    transition: 2s ease-in-out;
    border-radius:20px;
    border: none;
    margin-top:35px;
    background-color:white;
    font-size: 20px;
    margin-left:45px

}

.side-bar-2-popover-button-css{
    transition: 2s ease-in-out;
    margin-top:15px;
    border-radius:20px;
    border: none;
    background-color:white;
    font-size: 30px;
    margin-left:50px
}


.underline-animation {
    position: relative;
    overflow: hidden;
    display: inline-block;
    
}

.underline-animation:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px; /* Set the height of the underline */
    width: 0;
    background-color: black; /* Set the color of the underline */
    transition: width 0.3s ease; /* Set the animation duration and easing */
}

.underline-animation:hover:after {
    width: 100%;
}


.underline-animationn {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.underline-animationn:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px; /* Set the height of the underline */
    width: 0;
    background-color: black; /* Set the color of the underline */
    transition: width 0.3s ease; /* Set the animation duration and easing */
}

.underline-animationn:after {
    width: 100%;
}

  

.maintitletitle {
    font-size: 7ch;
    font-weight: 500;
}

.maintitle {
    font-size: 4ch;
    font-weight: 500;
}

.hidden-id-dsfgsdjhfgkjdshfgaskhjfgaksjdf{
    display:none;
}
.dhjasdhfjkldshfklajsdhfkjdhfkljashdfdgfjasddf {
    box-sizing: content-box; /* or box-sizing: border-box; depending on your layout */
    padding-top: 20px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 20px;
    word-wrap: break-word;
    white-space: normal;
    overflow: visible;
}


.Iro-Exam-Stuff-Css{
    font-size: 20px;
    padding-left:80px;
}


.hide-div-id-sdgflsadhfgasldfjhagfdhjfglafruifbmjtkligyufgvhlskjtryhfgiodsfgt{
    display:none
}

.cool-background {
    background-image: linear-gradient(
    45deg,
    hsl(219deg 33% 85%) 0%,
    hsl(219deg 33% 86%) 10%,
    hsl(220deg 33% 88%) 22%,
    hsl(220deg 33% 90%) 34%,
    hsl(220deg 33% 91%) 46%,
    hsl(220deg 33% 93%) 58%,
    hsl(220deg 33% 95%) 69%,
    hsl(220deg 33% 97%) 81%,
    hsl(220deg 33% 98%) 91%,
    hsl(0deg 0% 100%) 100%
    );
    padding:70px;
}


  .profile-name {
    margin-top: 20px;
  }

/* footer css */
.curved-text-wrapper {
    animation: spin 12s linear infinite; /* Adjust the duration and timing function as needed */
    border-radius: 50%;
    margin-left:200px;
    letter-spacing: 2px;
    color: gray;
    font-family: 'PT Sans Caption', sans-serif !important;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;

  }
  
  @keyframes spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }


  
  .curved-text-container {
    position: absolute;
    bottom: 0px; /* Position at the top */
    right:-10px;
    margin-bottom:30px;
    margin-left:200px;
    top: -100px; /* Adjust as needed to position the image within the curve */
    border-radius: 50%;
  }
  
  .circle-image {
    position: absolute;
    bottom: 0px; /* Position at the top */
    right: 53px; /* Position at the right */
    top: 55px; /* Adjust as needed to position the image within the curve */

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

.Marquee-css-div{
    background-color:white;
    height:250px;
    margin-top:150px;
    width:700px;
    margin-left:auto;
    margin-right:auto;
}

.shadow-div {
    background: linear-gradient(to right, rgb(255, 255, 255), transparent); /* Adjust colors as needed */
    width: 100px;
    height: 160px;
    position: absolute;
    z-index: 999;
    opacity: 0.6;
}

.shadow-div-two {
    background: linear-gradient(to left, rgb(255, 255, 255), transparent); /* Adjust colors as needed */
    width: 110px;
    height: 160px;
    position: absolute;
    margin-right:165px;
    right:0;
    z-index: 999;
    opacity: 0.6;
}

.hide-div-id-sdgflsadhfgasldfjhagfdhjfglafruifbmjtkligyufgvhlskjtryhfgiodsfgtt{
    display:none
}

.Marquee-css-image{
    width:100px;
    margin-left:70px;
    margin-right:70px;
    overflow: hidden;
    
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.nohoverimage{
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.Our-sponsors-text{
    font-size: 18px;
    text-align: center;
    padding-bottom:15px;
    color:grey
}

.haram-logo-social-media-2{
    display:none;
}


.id-logo-css-iro-dhfgasdfjhgasdjfhagsdkfhasdfgsjdhf{
    width:300px;
    display: flex;
    margin: auto;
    margin-bottom: 50px
}

.background-image-winners-3 {
    position: absolute;
    width: 100%;
    height: auto; /* Adjust height as needed */
    z-index: -1; /* Ensures the image is behind the content */
}

.hidden-winners{
    display: none
}

.hidden-2{
    display: none;
}

@media all and (max-width: 1405px) {

    .why-iro-div{
        width:600px;
        margin-left:25px;
    }

    .hidden-id-sdhfiwuefiyufvjbnrgklurg{
        display:none;
    }
    .hidden-id-dsfgsdjhfgkjdshfgaskhjfgaksjdf{
        display:block;
    }

    
    @media all and (max-width: 1400px) {

        .maintitletitle {
            font-size: 6ch;
            font-weight: 500;
        }

        .lowertitle-ls {
            font-size: 2.5ch;
            line-height: 1.8;
            
        }


        .lowertitle22 {
            padding-bottom: 5px;
            font-size: 2.3ch;     
        }

        .lowertitle2 {
            font-size: 1.9ch;
            color: black;
        }

        .mainimg {
            width:380px;
            height:auto;
        }

        .wwhyirolowertitle {
            text-align: center;
            font-size: 2ch;
            padding-bottom: 40px;
            margin:auto;
            width: 600px;
        }
    
    }
    
    @media all and (max-width: 1399px) {

        .social-media-icon-css{
            margin-left:50px;
        }

        .curved-text-container {
            display: none;
        }

    }

    
    @media all and (max-width: 1235px) {

        .btn-aboutbutton{
            display:none
        }

        .Competition-Format-resize-when-small-screen-hide-none {
            display: none;
        }
        
        .btn-resourcesbutton{
            display:none
        }
        .btn-resourcesbutton{
            display:none
        }
        
        .btn-newsbutton{
            display:none;
        }
        .btn-contactbutton{
            display:none;
        }

        .btn-DensityMediumIconbutton{
            display:block;
        }

        .discord-link{
            display:none;
        }
        .Competition-Format-resize-when-small-screen{
            display:none;
        }
        .btn-signInbutton {
            display:none
        }
       
        .btn-group{
            display:none;
        }

        .btn-registerbutton{
            display:none
        }

    }
  
    @media all and (max-width: 1200px) {

        .why-iro-div{
            width:450px;
            margin-left:25px;
            float:right
        }

        .lowertitle-ls {
            font-size: 2.5ch;
            line-height: 1.5;
            
        }

        .Competition-Format-resize-when-small-screen-hide-none {
            display: none;
        }
        

        .mainimg-two{
            width:420px;
            margin-top:70px
        }

        .maintitletitle {
            font-size: 5ch;
            font-weight: 500;
        }

        .lowertitle22 {
            padding-bottom: 5px;
            font-size: 2.7ch;
            
        }

        .dfjhasldkjfhasldklahsldfkjhasdlkjfhasldkjfhaskjdhlskdfhalskdjfhasljkdfhaslkdjfha{
            display:none
        }

        .wwhyirolowertitle {
            text-align: center;
            font-size: 2ch;
            padding-bottom: 40px;
            margin:auto;
            width: 700px;
        }
        
        @media all and (max-width: 1155px) {
            .side-bar-2-popover-button-csss{
                transition: 2s ease-in-out;
                margin-top:35px;
                border-radius:20px;
                border: none;
                background-color:white;
                font-size: 25px;
                margin-left:45px
            }

            
            .avater-size-css-two{
                margin-left: auto;
                margin-top:100px;
                margin-right:130px;
                width: 210px !important;
                height: 310px !important;
            }

        }

        @media all and (max-width: 995px) {

            .why-iro-setup-one{
                display:none;
            }

            .custmabominat {
                display: none;
            }

            .psuhonbttomsmallscreen {
                padding-bottom: 50px;
            }

            .emailbox-css{
                margin-top: 50px;
                border: 1px solid grey;
                padding:20px;
            }

            .lowertitle22 {
                padding-bottom: 5px;
                font-size: 2.3ch;     
            }

            .lowertitle-ls {
                font-size: 2.5ch;
                line-height: 1.0;
                
            }
              

           
            .maintitle {
                font-size: 3.5ch;
                font-weight: 500;
            }
            .rishab-jain-video-css{
                width: 420px;
            }
            .wwhyiro {
                margin-top: 40px;
                
                text-align: center;
                font-size: 4ch;
            }

            .smallscreenmenu {
                display: block;
            }

            .mainscreenmenu {
                display: none;
            }

            .twdasperandc {
                display: block;
            }

            .tswhenship {
                display: none;
            }

       
            .comptitle {
                font-size: 3.3ch;
                font-weight: 500;
                margin-top:60px;
                padding-bottom: 30px;
            }
            .wwhyirolowertitle {
                text-align: center;
                font-size: 2ch;
                padding-bottom: 40px;
                margin:auto;
                width: 500px;
            }
            .comptitle {
                font-size: 3.3ch;
                font-weight: 500;
                margin-top:60px;
                padding-bottom: 50px;
            }

            .blibblob {
                display: none;
                /*  Optional  */
                /*   box-shadow: inset 50px 50px 17px 0px #ff588b,
                  inset 100px 100px 17px 0px #66319f; */
              }

            .pandspad {
                padding-left: 1px;
            }
            
            .pandspadt {
                padding-left: 1px;
            }

            .mainlscreen {
                display: none;
            }

            .dhjasdhfjkldshfklajsdhfkjdhfkljashdfdgfjasddf{
                padding: 10px;
                word-wrap: break-word
            }

            
            .hide-div-id-sdgflsadhfgasldfjhagfdhjfglafruifbmjtkligyufgvhlskjtryhfgiodsfgtt{
                display:block
            }
            .hide-div-id-dhafeyuvbejhvczxbvvcmnbxmnzcxvmnxbvzsjdkjhdsfaljhfdsjriujukhrgjhkghbggg{
                display:none
            }

            @media all and (max-width: 886px) {

                .avater-size-css-two{
                    margin-left: auto;
                    margin-top:100px;
                    margin-right:130px;
                    width: 180px !important;
                    height: 280px !important;
                }
    
            }

            @media all and (max-width: 769px) {
                .visible-winners{
                    display: none;
                }
                .hidden-winners{
                    display: block
                }
            }
            
            @media all and (max-width: 767px) {
                .rowpad1 {
                    padding-top: 40px;
                } 

                .Competition-Format-resize-when-small-screen-hide-none {
                    display: none;
                }

                .custmabominat {
                    display: none;
                }

                .psuhonbttomsmallscreen {
                    padding-bottom: 50px;
                }
                  

               
                .maintitle {
                    font-size: 3.5ch;
                    font-weight: 500;
                }
                .rishab-jain-video-css{
                    width: 420px;
                }
                .wwhyiro {
                    margin-top: 40px;
                    
                    text-align: center;
                    font-size: 4ch;
                }

                .smallscreenmenu {
                    display: block;
                }

                .mainscreenmenu {
                    display: none;
                }

                .twdasperandc {
                    display: block;
                }

                .tswhenship {
                    display: none;
                }

           
                .comptitle {
                    font-size: 3.3ch;
                    font-weight: 500;
                    margin-top:60px;
                    padding-bottom: 30px;
                }
                .wwhyirolowertitle {
                    text-align: center;
                    font-size: 2ch;
                    padding-bottom: 40px;
                    margin:auto;
                    width: 500px;
                }
                .comptitle {
                    font-size: 3.3ch;
                    font-weight: 500;
                    margin-top:60px;
                    padding-bottom: 50px;
                }

                .blibblob {
                    display: none;
                    /*  Optional  */
                    /*   box-shadow: inset 50px 50px 17px 0px #ff588b,
                      inset 100px 100px 17px 0px #66319f; */
                  }

                .pandspad {
                    padding-left: 1px;
                }
                
                .pandspadt {
                    padding-left: 1px;
                }

                .sidelinesectwo {
                    display: none;
                }
                #specgptimg {
                    display: none;
                }
                .mainimg {
                    padding-top: 20px;
                }
                .rowpad2 {
                    padding-top: 40px;
                }
                .rowpad22 {
                    padding-top: 40px;
                }
                #sidelinesectwo {
                    display: none;
                }
                #secmainsectwo {
                    width: 600px;
                }

                .why-iro-setup-two-hidden{
                    display:none;
                }
                .why-iro-setup-two-hidden-other-one{
                    display:block;
                    width:auto;
                }
                .why-iro-setup-two{
                    display:none
                }

                .dhjasdhfjkldshfklajsdhfkjdhfkljashdfdgfjasddf{
                    box-sizing: content-box; /* or box-sizing: border-box; depending on your layout */
                    padding: 5px;
                    word-wrap: break-word;
                    white-space: normal;
                    overflow: visible;
                }

                .lowertitlee {
                    font-size: 1.8ch;
                    
                }
                .lowertitleee {
                    font-size: 1.8ch;
                }
                
                .maintitlee {
                    font-size: 3ch;
                    font-weight: 500;
                }
                .maintitleee {
                    font-size: 3ch;
                    font-weight: 500;
                }
                

                .lowertitlee {
                    font-size: 1.8ch;
                }
                .lowertitleee {
                    font-size: 1.8ch;
                }
                

                .comptitle {
                    font-size: 4ch;
                    font-weight: 500;
                    padding-bottom: 50px;
                }

                .hidden-id-dsfgsdjhfgkjdshfgaskhjfgaksjdf{
                    display:none;
                }
                .hidden-id-dsfgsdjhfgkjdshfgaskhjfgaksjdff{
                    display:block;
                    width:auto;
                }

                .hide-div-id-sdgflsadhfgasldfjhagfdhjfglafruifbmjtkligyufgvhlskjtryhfgiodsfgt{
                    display:block
                }
                .hide-div-id-dhafeyuvbejhvczxbvvcmnbxmnzcxvmnxbvzsjdkjhdsfaljhfdsjriujukhrgjhkghbgg{
                    display:none
                }


                @media (max-width: 768px) {
                    .second-video{
                        display: block;
                    }
                    .first-video{
                        display: none;
                    }
                  }
  
                  
                @media all and (max-width: 735px) {

                    .avater-size-css-two{
                        margin-top:100px;
                        margin-right:110px;
                        width: 150px !important;
                        height: 250px !important;
                    }
        
                }

                @media all and (max-width: 537px) {
                    .comptitleee {
                        font-size: 3.6ch;
                        font-weight: 500;
                        padding-bottom: 50px;
                    }

                    .lowertitle-ls {
                        margin-top: 20px;
                        text-align: center;
                        font-size: 2.5ch;
                        line-height: 1.0;
                        
                    }
                }

                /* 3343434343423409234098234098234098234098230498203948092384092834092834092384092384092384092834092384092384092834 */

                

                @media all and (max-width: 450px) {
                    .rowpad1 {
                        padding-top: 10px;
                    }

                    .foot-finals-pic {
                        display: none;
                    }

                    .iro-award-ceremony-finalist-1 {
                        display: block;
                    }

                    .iro-award-ceremony-finalist {
                        display: none;
                    }

                    .iro-teamchallnage-finalsts-1 {
                        display: block;
                    }

                    .iro-jimmytalk-finalists-1 {
                        display: block;
                    }

                    .iro-jimmytalk-finalists {
                        display: none;
                    }

                    .iro-teamchallnage-finalsts {
                        display: none;

                    }

                    .finalsfooterphoto-2 {
                        display: block;
                    }

                    .threefinalistphotos {
                        align-items: center;
                        justify-content: center;
                        display: flex;

                    }

                    .finalsfooterphoto-1 {
                        display: none;
                    }

                    .containere {
                        
                        position: relative;
                        display: flex;
                        align-items: center;
                        gap: 14px; /* Adjust based on gap-x-6 and sm:gap-x-3.5 */
                        overflow: hidden;
                        background-image: url(banner.svg);
                        background-size: cover;
                        padding: 7px 20px; /* Adjust based on px-6 py-2.5 and sm:px-3.5 */
                        font-size: 0.875rem; /* text-sm */
                        line-height: 1.25rem; /* leading-6 */
                      }

                      .aboutfinalsiamge {
                        display: none;
                      }

                      .aboutfinalsiamget {
                        display: block;
                      }


                    .Exceptionally-motivated-Limitless-potential {
                        font-size: 1.2ch;
                    }

                    .harvardalertooltip {
                        display: none;
                    }

                   
                   
                    .curved-text-wrapper {
                        display: none;
                      }
                      
                      @keyframes spin {
                        from {
                          transform: rotate(360deg);
                        }
                        to {
                          transform: rotate(0deg);
                        }
                      }

                    .emailbox-css{
                        margin-top: 50px;
                        border: 1px solid grey;
                        padding:20px;
                    }

                    .rishab-jain-video-css{
                        width: 400px;
                    }

                    .mainlscreen {
                        display: none;
                    }

                    .footertos {
                        display: none;
                    }
/* 
                    .termsacondsitonfs {
                        
                    } */

                    .custmabominat {
                        display: none;
                    }

                    .psuhonbttomsmallscreen {
                        padding-bottom: 50px;
                    }
                      

                   
                    .maintitle {
                        font-size: 3.5ch;
                        font-weight: 500;
                    }
                   
                    .wwhyiro {
                        margin-top: 40px;
                        
                        text-align: center;
                        font-size: 4ch;
                    }

                    .smallscreenmenu {
                        display: block;
                    }

                    .mainscreenmenu {
                        display: none;
                    }

                    .twdasperandc {
                        display: block;
                    }

                    .tswhenship {
                        display: none;
                    }

               
                    .comptitle {
                        font-size: 3.3ch;
                        font-weight: 500;
                        margin-top:60px;
                        padding-bottom: 30px;
                    }
                    .wwhyirolowertitle {
                        text-align: center;
                        font-size: 2ch;
                        padding-bottom: 40px;
                        margin:auto;
                        width: auto;
                    }
                    .comptitle {
                        font-size: 3.3ch;
                        font-weight: 500;
                        margin-top:60px;
                        padding-bottom: 50px;
                    }

                    .blibblob {
                        display: none;
                        /*  Optional  */
                        /*   box-shadow: inset 50px 50px 17px 0px #ff588b,
                          inset 100px 100px 17px 0px #66319f; */
                      }

                    .pandspad {
                        padding-left: 1px;
                    }
                    
                    .pandspadt {
                        padding-left: 1px;
                    }

                    #sidelinesectwo {
                        display: none;
                    }
                    #specgptimg {
                        display: none;
                    }
                    #whyiro-img {
                        display: none;
                    }
                    .aboutbutton {
                        display: none;
                    }
                    .topsearchbar {
                        display: none;
                    }
                    .resbutton {
                        display: none;
                    }
                    .mainimg {
                        display: none;
                    }
                    .rowpad2 {
                        padding-top: 10px;
                    }
                    .rowpad22 {
                        padding-top: 10px;
                    }

                    #display-none-main-iro-logo{
                        display:none
                    }

                    .gotdisatsm {
                        display: none;
                    }

                    .Competition-Format-resize-when-small-screen-hide-none {
                        display: block;
                    }

                    .comptitleee {
                        font-size: 3.3ch;
                        font-weight: 500;
                        padding-bottom: 50px;
                    }


                    .hidden-1{
                        display: none;
                    }
                    
                    .hidden-2{
                        display: block;
                    }

                    .secondinsideytwo {
                        display: inline-block;
                        border-radius: 20px;
                        border: none;
                        background-color: white;
                        color: black;
                        padding: 10px;
                        margin: 10px 0;
                    }

                }

                @media all and (max-width: 431px) {

                    .avater-size-css-two{
                        /* margin-left: 1px;
                        margin-top:1px;
                        position: absolute;
                        margin-right:50%; */
                        width: 150px !important;
                        height: 250px !important;
                    }

                    .box-width-for-side-corner-thing-dshfsalhdfadsf{
                        
                        
                       
                        position: absolute;
                        left:150px;
                        
                    }
        
                }

                @media all and (max-width: 385px) {

                    .rishab-jain-video-css{
                        width: 380px;
                    }

                    .comptitleee {
                        font-size: 3ch;
                        font-weight: 500;
                        padding-bottom: 50px;
                    }
                }

                
                @media all and (max-width: 360px) {

                    .avater-size-css-two{
                        margin-left: auto;
                        margin-top:100px;
                        margin-right:160px;
                        width: 130px !important;
                        height: 230px !important;
                    }
        
                }



            }
        }
    }
}
