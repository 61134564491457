body {
    margin: 0;
    padding: 0;
}

.black-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    background-color: white; /* Add a background color for visibility */
    color: black; /* Add a text color for visibility */
    overflow-y: hidden;
}

.black-box-content{
    background-color: white;
    border: 1px solid black;
    border-radius: 2px;
    width:500px;
    height:540px
}

.sign-in{
    font-size: 30px;
    text-align: center;
    margin-top:25px;
}

.sign-in-button{
    margin-top:40px
}

.sign-in-buttonn{
    color:black;
    border-radius: 0 !important;
    border:1px solid rgb(220, 220, 220);
    background-color:white;
}
.sign-in-buttonn:hover{
    color:black;
    border:1px solid black;
    background-color:white;
}

.btn-outline-google{
    color: black;
    border-radius: 20px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.5);
    font-size: 17px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin:auto;
    padding:10px;
    padding-left:40px;
    padding-right:40px;
}

.btn-outline-apple{
    color: black;
    border-radius: 20px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.5);
    font-size: 17px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin:auto;
    padding:10px;
    padding-left:40px;
    padding-right:40px;
    margin-top:30px
}

.irologo{
    margin-top:25px;
    justify-content: center;
    align-self: center;
    display: flex;
}


@media all and (max-width: 500px) {
    .black-box-content{
        background-color: white;
        border: 1px solid black;
        border-radius: 2px;
        width:auto;
        height:auto;
        padding-bottom: 20px;
    }    
}